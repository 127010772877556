












































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { appointmentModule } from '@/store/modules/appointment';
import { searchModule } from '@/store/modules/search';
import AppointmentDate from '@/components/DialogAppointment/AppointmentDate.vue';
import TimeSelect from '@/components/DialogAppointment/TimeSelect.vue';
import { SearchMail } from '@/interfaces/search-mail';

@Component({
    components: { TimeSelect, AppointmentDate }
})
export default class DialogPre extends Vue {
    @Prop({ required: true }) search!: SearchMail;
    form = { date: '', hour: '' };
    error = '';
    step = 0;

    next() {
        this.step++;
    }

    async store() {
        try {
            await appointmentModule.pre({
                clienteId: this.search.customerId,
                esClientePrincipal: this.search.customer.type === 'principal',
                agenteId: this.search.agenteId,
                projectId: appointmentModule.selectedModel.project._id,
                typologyId: appointmentModule.selectedModel._id,
                phone: this.search.customer.phone,
                cellphone: this.search.customer.cellphone,
                anexo: this.search.customer.anexo,
                fecha: this.form.date,
                hora: this.form.hour
            });
            this.next();
        } catch ({ mensaje }) {
            this.error = mensaje;
            this.step = 400;
        }
    }

    close() {
        searchModule.closeOrOpenDialog(false);
    }
}
